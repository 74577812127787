<template>
    <div class="page recordBox">
        <el-row :gutter="10">
            <el-col :span="showList ? 4 : 1" :class="['p_r', showList ? '' : 'el-col-unfold']">
                <div :class="['unfoldClassifyBtn', showList ? 'el-icon-arrow-left':'el-icon-arrow-right']" @click="showList = !showList"></div>
                <div class="bg-white" v-show="showList">
                    <el-descriptions title="档案分类"></el-descriptions>
                    <el-tree class="treeData" :data="treeData" :props="defaultProps" v-loading="treeLoading" ref="myTree" node-key="id" highlight-current
                             @node-click="selRecord">
                        <div slot-scope="{ node, data }" class="omit">
                            <el-tooltip class="item" effect="dark" :content="node.label" placement="top-start">
                                <div>{{ node.label }}</div>
                            </el-tooltip>
                        </div>
                    </el-tree>
                </div>
            </el-col>
            <el-col :span="showList ? 20 : 24" :class="[showList ? '' : 'el-col-unfold']">
                <div class="tableForm">
                    <div class="bg-white m_b1">
                        <el-form size="small" class="query-form dynamicForm" ref="inputForm" :model="inputForm"
                                 label-width="120px" @keyup.enter.native="getList(1)">
                            <el-form-item prop="keyword" label="输入搜索：">
                                <el-input v-model.trim="inputForm.keyword" maxlength="50"
                                          placeholder="关键字搜索"
                                          clearable></el-input>
                            </el-form-item>
                            <el-form-item prop="archivesBasicDataType" label="档案类型：">
                                <el-select class="w100i" v-model="inputForm.archivesBasicDataType" clearable>
                                    <el-option
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value"
                                            v-for="item in $dictUtils.getDictList('archivesBasicDataType')">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item prop="archivesDataName" label="题名：">
                                <el-input v-model.trim="inputForm.archivesDataName" maxlength="50" placeholder="请输入题名"
                                          clearable></el-input>
                            </el-form-item>
                            <el-form-item prop="warehousingState" label="状态：">
                                <el-select class="w100i" v-model="inputForm.warehousingState" clearable>
                                    <el-option
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value"
                                            v-for="item in $dictUtils.getDictList('warehousing_state')">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item prop="quanzongNumber" label="全宗号：">
                                <el-input v-model.trim="inputForm.quanzongNumber" maxlength="50" placeholder="请输入全宗号"
                                          clearable></el-input>
                            </el-form-item>
                            <el-form-item prop="quanzongName" label="全宗名称：">
                                <el-input v-model.trim="inputForm.quanzongName" maxlength="50" placeholder="请输入全宗名称"
                                          clearable></el-input>
                            </el-form-item>
                            <el-form-item prop="reference" label="档号：">
                                <el-input v-model.trim="inputForm.reference" maxlength="50" placeholder="请输入档号"
                                          clearable></el-input>
                            </el-form-item>
                            <el-form-item prop="createBy" label="创建人：">
                                <el-select v-model="inputForm.createBy" filterable placeholder="请选择" clearable
                                           class="w100i">
                                    <el-option v-for="item in userList" :key="item.id" :label="item.name"
                                               :value="item.name"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item prop="createDate" label="创建时间：">
                                <el-date-picker class="w100i"
                                                v-model="inputForm.createDate" value-format="yyyy-MM-dd HH:mm:ss"
                                                format="yyyy-MM-dd HH:mm:ss"
                                                type="datetimerange" clearable start-placeholder="开始时间"
                                                end-placeholder="结束时间">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item :label="item.forName ? item.forName : item.basicName + '：'" :prop="item.code"
                                          v-for="(item, index) in config"
                                          :key="index" v-if="item.dataWhere == 0">
                                <el-input v-model.trim="inputForm[item.code]" :maxlength="item.maxLength"
                                          :placeholder="item.forName ? '请输入' + item.forName : '请输入' + item.basicName"
                                          clearable
                                          v-if="item.archivesBasicTypeCode == 'text' && !item.archivesBasicMultiselectList.length"></el-input>
                                <!--数值、浮点数-->
                                <el-input v-model.trim="inputForm[item.code]"
                                          :maxlength="item.archivesBasicTypeCode == 'integer'? '8':'11'"
                                          @input="getNumType(item)"
                                          :placeholder="item.forName ? '请输入' + item.forName : '请输入' + item.basicName"
                                          clearable
                                          v-if="item.archivesBasicTypeCode == 'integer' || item.archivesBasicTypeCode == 'double'"></el-input>
                                <el-select class="w100i" v-model="inputForm[item.code]"
                                           :multiple="item.archivesBasicMultiselectList[0].selectBy == 1" collapse-tags
                                           :placeholder="item.forName ? '请选择' + item.forName : '请选择' + item.basicName"
                                           clearable
                                           v-if="item.archivesBasicMultiselectList.length">
                                    <el-option v-for="(v,i) in item.archivesBasicMultiselectList" :key="i"
                                               :label="v.archivesBasicValue"
                                               :value="v.archivesBasicValue"></el-option>
                                </el-select>
                                <el-date-picker class="w100i"
                                                v-model="inputForm[item.code]" value-format="yyyy-MM-dd HH:mm:ss"
                                                format="yyyy-MM-dd HH:mm:ss"
                                                type="datetimerange" clearable start-placeholder="开始时间"
                                                end-placeholder="结束时间"
                                                v-if="item.archivesBasicTypeCode == 'date' && item.dataFormat == 'yyyy-MM-dd HH:mm:ss'">
                                </el-date-picker>
                                <el-date-picker class="w100i"
                                                v-model="inputForm[item.code]" value-format="yyyy-MM-dd HH:mm:ss"
                                                format="yyyy-MM-dd"
                                                type="daterange" clearable start-placeholder="开始时间"
                                                end-placeholder="结束时间"
                                                v-if="item.archivesBasicTypeCode == 'date' && item.dataFormat == 'yyyy-MM-dd'">
                                </el-date-picker>
                                <el-date-picker class="w100i"
                                                v-model="inputForm[item.code]" value-format="yyyy-MM-dd HH:mm:ss"
                                                format="yyyyMMdd"
                                                type="daterange" clearable start-placeholder="开始时间"
                                                end-placeholder="结束时间"
                                                v-if="item.archivesBasicTypeCode == 'date' && item.dataFormat == 'yyyyMMdd' || item.archivesBasicTypeCode == 'date' && !item.dataFormat">
                                </el-date-picker>
                            </el-form-item>
                        </el-form>
                        <div class="flex_b_c">
                            <div>
                                <el-button type="primary" icon="el-icon-search" @click="getList(1)" size="small">查询
                                </el-button>
                                <el-button @click="reset()" size="small" icon="el-icon-refresh-right">重置</el-button>
                            </div>
                            <div>
                                <el-button type="primary" size="small" icon="el-icon-search" @click="advancedSearch()">
                                    高级检索
                                </el-button>
                                <el-button type="primary" size="small" icon="el-icon-plus"
                                           v-show="hasPermission('recordation:list:add')"
                                           @click="addEdit(null, 'add')">收集
                                </el-button>
                                <el-button type="danger" size="small" icon="el-icon-delete"
                                           v-show="hasPermission('recordation:list:delete')"
                                           @click="del()">批量删除
                                </el-button>
                            </div>
                        </div>
                    </div>
                    <div class="bg-white tableBgBox">
                        <div class="text_right">
                            <el-button size="small" icon="el-icon-upload2"
                                       v-show="hasPermission('recordation:list:linkage')"
                                       @click="fileLinkageData()">文件挂接
                            </el-button>
                            <el-button icon="el-icon-download" size="small" v-show="hasPermission('recordation:list:download')"
                                       @click="downloadData()">批量下载</el-button>
                            <el-upload class="i_block m_r1 m_l1"
                                       ref="upload"
                                       action="action" :show-file-list="false"
                                       :http-request="getImportData"
                                       :before-upload="beforeUpload" v-if="hasPermission('recordation:list:import') && searchForm.archivesBasicDataId">
                                <el-button size="small" icon="el-icon-upload2"> 批量导入
                                </el-button>
                            </el-upload>
                            <el-button size="small" v-show="hasPermission('recordation:list:export')"
                                       @click="exportData()">
                                <i class="icon-piliangdaochu iconfont buIcon"/>批量导出
                            </el-button>
                            <el-button icon="el-icon-download" size="small"
                                       v-show="hasPermission('recordation:list:import')"
                                       @click="importTemplate()">导入模板下载
                            </el-button>
                        </div>
                        <el-table
                                :data="dataList"
                                v-loading="loading"
                                size="small"
                                @selection-change="selectionChangeHandle"
                                @sort-change="sortChange"
                                class="table" ref="multipleTable" :row-key='idKey'>
                            <el-table-column type="selection" :reserve-selection='true' fixed/>
                            <el-table-column prop="archivesBasicDataName" label="档案分类" min-width="120" show-overflow-tooltip/>
                            <el-table-column prop="archivesBasicDataType" label="档案类型" min-width="120" show-overflow-tooltip/>
                            <el-table-column prop="archivesDataName" label="题名" min-width="120" show-overflow-tooltip/>
                            <el-table-column prop="warehousingState" label="状态" min-width="120" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="quanzongNumber" label="全宗号" min-width="120"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="quanzongName" label="全宗名称" min-width="120"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="reference" label="档号" min-width="120"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column v-for="item in config" :key="item.archivesBasicId"
                                             :prop="item.code"
                                             :label="item.forName ? item.forName : item.basicName"
                                             v-if="item.dataSelect == 0"
                                             :sortable="item.archivesBasicTypeCode == 'date' || item.archivesBasicTypeCode == 'integer' ? 'custom' : false"
                                             show-overflow-tooltip min-width="120">
                                <template slot="header" slot-scope="scope">
                                    <el-tooltip class="item" effect="dark" :content="item.forName ? item.forName : item.basicName" placement="top-start">
                                        <span class="omit">{{item.forName ? item.forName : item.basicName}}</span>
                                    </el-tooltip>
                                </template>
                                <template slot-scope="scope">
                                    <span v-if="item.archivesBasicTypeCode == 'date'">{{metadataDateFormatValue(item, scope.row.archivesData[item.code])}}</span>
                                    <span v-else>{{ scope.row.archivesData[item.code]}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="queryCount" label="查看次数" width="100" sortable show-overflow-tooltip
                                             v-if="hasPermission(searchForm.archivesBasicDataId + '-queryPermissions-1')"/>
                            <el-table-column prop="downloadCount" label="下载次数" width="100" sortable
                                             show-overflow-tooltip
                                             v-if="hasPermission(searchForm.archivesBasicDataId + '-downloadPermissions-1')"/>
                            <el-table-column prop="printCount" label="打印次数" width="100" sortable show-overflow-tooltip
                                             v-if="hasPermission(searchForm.archivesBasicDataId + '-printPermissions-1')"/>
                            <el-table-column prop="createBy" label="创建人" min-width="120"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="createDate" label="创建时间" min-width="120" sortable
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column fixed="right" width="180" label="操作">
                                <template slot-scope="scope">
                                    <el-button type="text" size="small" v-if="hasPermission('recordation:list:view')"
                                               @click="addEdit(scope.row, 'view', scope.$index)">详情
                                    </el-button>
                                    <el-button type="text" size="small" v-if="scope.row.archivesBasicDataType == '卷内库'" @click="dossier(scope.row)">案卷</el-button>
                                    <el-button type="text" size="small" v-if="scope.row.archivesBasicDataType == '案卷库'" @click="memorandum(scope.row)">卷内</el-button>
                                    <el-button type="text" size="small"
                                               v-if="hasPermission('recordation:list:edit') && scope.row.borrowingState != '已借出' && scope.row.warehousingState == '在库' || scope.row.warehousingState == '残损'"
                                               @click="addEdit(scope.row, 'edit')">修改
                                    </el-button>
                                    <el-button type="text" size="small" v-if="hasPermission('recordation:list:delete')"
                                               @click="del(scope.row.id)">删除
                                    </el-button>
                                    <el-button type="text" size="small" v-if="hasPermission('recordation:list:copy')"
                                               @click="copy(scope.row)">复制
                                    </el-button>
                                    <el-button type="text" size="small"
                                               v-if="hasPermission(scope.row.archivesBasicDataId + '-downloadPermissions-1') || hasPermission(scope.row.id + '-downloadPermissions-1')"
                                               @click="download(scope.row)">下载
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-pagination
                                @size-change="sizeChangeHandle"
                                @current-change="currentChangeHandle"
                                :current-page="searchForm.current"
                                :page-sizes="[10, 20, 50, 100]"
                                :page-size="searchForm.size"
                                :total="total"
                                background
                                layout="total, sizes, prev, pager, next, jumper">
                        </el-pagination>
                    </div>
                </div>
            </el-col>
        </el-row>
        <el-dialog title="档案复制" width="50%" :close-on-click-modal="false" :visible.sync="visible" v-if="visible">
            <el-form size="small" :model="copyData" ref="copyData" label-width="100px">
                <el-form-item class="w100i" label="档号：" prop="reference"
                              :rules="[{ required: true, whitespace: true, message: '请输入档号', trigger: 'blur' }]">
                    <el-input v-model.trim="copyData.reference" placeholder="请输入档号（限50字）" maxlength="50" @input="referenceIpt"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="text_center">
                <el-button size="small" @click="visible = false">取消</el-button>
                <el-button size="small" type="primary" @click="saveCopyData()" v-noMoreClick>保存</el-button>
            </div>
        </el-dialog>
        <ListForm ref="listForm" @refreshDataList="getList()"></ListForm>
        <DossierDetails ref="dossierDetails"></DossierDetails>
        <MemorandumDetails ref="memorandumDetails"></MemorandumDetails>
        <ExportData ref="exportData"></ExportData>
        <FileLinkage ref="fileLinkage" @refreshDataList="srhListData(1)"></FileLinkage>
        <ImportData ref="importData" @refreshDataList="srhListData(1)"></ImportData>
        <AdvancedSearch ref="advancedSearch" @getDataList="getSrhCondition"></AdvancedSearch>
    </div>
</template>

<script>
    import ListForm from './listForm'
    import DossierDetails from './dossierDetails'
    import MemorandumDetails from './memorandumDetails'
    import ExportData from '../collect/exportData'
    import ImportData from '../collect/importData'
    import FileLinkage from '../collect/fileLinkage'
    import AdvancedSearch from '@/components/advancedSearch/advancedSearch'

    export default {
        components: {
            ListForm, DossierDetails, MemorandumDetails, ExportData, ImportData, AdvancedSearch, FileLinkage
        },
        data() {
            return {
                showList: true,
                treeData: [],
                treeLoading: false,
                defaultProps: {
                    children: 'children',
                    label: 'archivesBasicName'
                },
                searchForm: {
                    archivesBasicDataId: null,
                    current: 1,
                    size: 10,
                    whereList: [],
                    sortArchivesDataElasticsearch: [],
                    fieldOrList: [],
                    fieldWhereList: [],
                },
                advancedList: [],
                inputForm: {
                    keyword: '',
                    archivesBasicDataType: '',
                    warehousingState: '',
                    archivesDataName: '',
                    quanzongNumber: '',
                    quanzongName: '',
                    reference: '',
                    createBy: '',
                    createDate: [],
                },
                config: [],
                loading: false,
                dataList: [],
                total: 0,
                archivesBasicDataName: '',
                dataListSelections: [],
                idKey: 'id',
                userList: [],
                visible: false,
                copyData: {},
            }
        },
        mounted() {
            this.searchTree()
            this.getUserList()
            this.selRecord()
        },
        methods: {
            // 查询树状数据
            searchTree() {
                this.treeLoading = true
                this.$axios(this.api.record.selectArchivesBasicData).then((res) => {
                    if (res.status) {
                        this.treeData = res.data
                        this.treeData.unshift({
                            archivesBasicName: '全部',
                            id: '',
                            children: []
                        })
                        this.$nextTick(() => {
                            this.$refs.myTree.setCurrentKey(this.treeData[0].id);
                        });
                    }
                    this.treeLoading = false
                })
            },
            // 选择档案分类
            selRecord(val) {
                this.advancedList = []
                this.searchForm.whereList = []
                this.searchForm.sortArchivesDataElasticsearch = []
                this.searchForm.fieldWhereList = []
                this.searchForm.archivesBasicDataId = null
                if(val && val.id) {
                    this.searchForm.archivesBasicDataId = val.id
                    this.archivesBasicDataName = val.archivesBasicName
                    this.$axios(this.api.record.basicdataGetById + '/' + val.id).then(data => {
                        if (data.status) {
                            this.inputForm = {
                                keyword: '',
                                archivesBasicDataType: '',
                                warehousingState: '',
                                archivesDataName: '',
                                quanzongNumber: '',
                                quanzongName: '',
                                reference: '',
                                createBy: '',
                                createDate: [],
                            }
                            this.config = data.data.archivesBasicDataGroup
                            this.config.map(item => {
                                if (item.dataWhere == 0) {
                                    this.$set(item, 'value', '')
                                    this.$set(this.inputForm, item.code, '')
                                }
                            })
                            this.$refs.multipleTable.clearSelection()
                            this.getList(1)
                        }
                    })
                } else {
                    this.archivesBasicDataName = '全部'
                    this.getAllData()
                }
            },
            // 全部分类元数据字段
            getAllData() {
                this.$axios(this.api.record.getArchivesBasicByRequired).then((res) => {
                    if (res.status) {
                        this.config = res.data
                        this.config.map(item => {
                            if (item.dataWhere == 0) {
                                this.$set(item, 'value', '')
                                this.$set(this.inputForm, item.code, '')
                            }
                        })
                        this.srhListData(1)
                    }
                })
            },
            // 查询
            getList(type) {
                this.$refs.multipleTable.clearSort()
                this.searchForm.whereList = []
                this.searchForm.sortArchivesDataElasticsearch = []
                this.searchForm.fieldWhereList = []
                let keys = Object.keys(this.inputForm)
                let values = Object.values(this.inputForm)
                keys.map((key, index) => {
                    this.config.map(item => {
                        if (item.code == key && values[index]) {
                            if (item.archivesBasicTypeCode == 'text') {
                                // 多选遍历多选的值
                                if (Array.isArray(values[index])) {
                                    values[index].map(v => {
                                        this.searchForm.whereList.push({
                                            archivesBasicTypeMethodCode: "=",
                                            archivesBasicTypeCode: item.archivesBasicTypeCode,
                                            key: item.code,
                                            value: v
                                        })
                                    })
                                } else {
                                    this.searchForm.whereList.push({
                                        archivesBasicTypeMethodCode: "%",
                                        archivesBasicTypeCode: item.archivesBasicTypeCode,
                                        key: item.code,
                                        value: values[index]
                                    })
                                }
                            }
                            if (item.archivesBasicTypeCode == 'integer') {
                                this.searchForm.whereList.push({
                                    archivesBasicTypeMethodCode: "=",
                                    archivesBasicTypeCode: item.archivesBasicTypeCode,
                                    key: item.code,
                                    value: values[index]
                                })
                            }
                            if (item.archivesBasicTypeCode == 'date') {
                                this.searchForm.whereList.push({
                                    archivesBasicTypeMethodCode: ">=",
                                    archivesBasicTypeCode: item.archivesBasicTypeCode,
                                    key: item.code,
                                    value: values[index] ? values[index][0] : ''
                                })
                                this.searchForm.whereList.push({
                                    archivesBasicTypeMethodCode: "<=",
                                    archivesBasicTypeCode: item.archivesBasicTypeCode,
                                    key: item.code,
                                    value: values[index] ? values[index][1] : ''
                                })
                            }
                        }
                    })
                })
                this.srhListData(type)
            },
            // 列表数据查询
            srhListData(type) {
                if (type == 1) {
                    this.searchForm.current = 1
                }
                if (type == 0) {
                    this.$refs.multipleTable.clearSelection()
                }
                this.loading = true
                if (this.inputForm.archivesBasicDataType) {
                    this.searchForm.fieldWhereList.push({
                        archivesBasicTypeMethodCode: "=",
                        archivesBasicTypeCode: "text",
                        key: "archivesBasicDataType",
                        value: this.inputForm.archivesBasicDataType,
                        "method":"and",
                    })
                }
                if (this.inputForm.warehousingState) {
                    this.searchForm.fieldWhereList.push({
                        archivesBasicTypeMethodCode: "=",
                        archivesBasicTypeCode: "text",
                        key: "warehousingState",
                        value: this.inputForm.warehousingState,
                        "method":"and",
                    })
                }
                if (this.inputForm.archivesDataName) {
                    this.searchForm.fieldWhereList.push({
                        archivesBasicTypeMethodCode: "%",
                        archivesBasicTypeCode: "text",
                        key: "archivesDataName",
                        value: this.inputForm.archivesDataName,
                        "method":"and",
                    })
                }
                if (this.inputForm.quanzongNumber) {
                    this.searchForm.fieldWhereList.push({
                        archivesBasicTypeMethodCode: "%",
                        archivesBasicTypeCode: "text",
                        key: "quanzongNumber",
                        value: this.inputForm.quanzongNumber,
                        "method":"and",
                    })
                }
                if (this.inputForm.quanzongName) {
                    this.searchForm.fieldWhereList.push({
                        archivesBasicTypeMethodCode: "%",
                        archivesBasicTypeCode: "text",
                        key: "quanzongName",
                        value: this.inputForm.quanzongName,
                        "method":"and",
                    })
                }
                if (this.inputForm.reference) {
                    this.searchForm.fieldWhereList.push({
                        archivesBasicTypeMethodCode: "%",
                        archivesBasicTypeCode: "text",
                        key: "reference",
                        value: this.inputForm.reference,
                        "method":"and",
                    })
                }
                if (this.inputForm.createBy) {
                    this.searchForm.fieldWhereList.push({
                        archivesBasicTypeMethodCode: "=",
                        archivesBasicTypeCode: "text",
                        key: "createBy",
                        value: this.inputForm.createBy,
                        "method":"and",
                    })
                }
                if (this.inputForm.createDate[0]) {
                    this.searchForm.fieldWhereList.push({
                        archivesBasicTypeMethodCode: ">=",
                        archivesBasicTypeCode: "date",
                        key: "createDate",
                        value: this.inputForm.createDate[0],
                        "method":"and",
                    })
                    this.searchForm.fieldWhereList.push({
                        archivesBasicTypeMethodCode: "<=",
                        archivesBasicTypeCode: "date",
                        key: "createDate",
                        value: this.inputForm.createDate[1],
                        "method":"and",
                    })
                }
                this.searchForm.keyword = this.inputForm.keyword
                this.$axios(this.api.record.queryElasticsearchTemplatePage, this.searchForm, 'post').then((res) => {
                    if (res.status) {
                        this.dataList = res.data.records
                        if (this.dataList.length == 0 && this.searchForm.current > 1) {
                            this.searchForm.current--
                            this.srhListData()
                        }
                        this.total = parseInt(res.data.total)
                    } else {
                        this.dataList = []
                        this.$message.error('查询失败');
                    }
                    this.loading = false
                })
            },
            // 列表排序
            sortChange(column) {
                if (column.prop == "createDate" || column.prop == "queryCount" || column.prop == "downloadCount" || column.prop == "printCount") {
                    return
                }
                this.searchForm.sortArchivesDataElasticsearch = [
                    {
                        key: column.prop,
                        sort: column.order == "descending" ? 'desc' : (column.order == "ascending" ? 'asc' : ''),
                    }
                ]
                this.srhListData()
            },
            // 高级检索
            advancedSearch() {
                this.$refs.advancedSearch.init(this.config, this.advancedList)
            },
            // 获取高级检索条件
            getSrhCondition(data) {
                if (data) {
                    this.advancedList = data.advancedList
                    this.searchForm.whereList = data.whereList
                    this.srhListData(1)
                }
            },
            // 案卷
            dossier(row) {
                this.$refs.dossierDetails.init(row)
            },
            // 卷内
            memorandum(row) {
                this.$refs.memorandumDetails.init(row)
            },
            // 新增
            addEdit(row, method, index) {
                if (method == 'add') {
                    this.$refs.listForm.init(row, method, this.searchForm.archivesBasicDataId)
                } else {
                    let searchForm = JSON.parse(JSON.stringify(this.searchForm))
                    searchForm.index = (searchForm.current - 1) * searchForm.size + (index + 1)
                    searchForm.total = this.total
                    searchForm.size = 1
                    searchForm.current = searchForm.index
                    this.$refs.listForm.init(row, method, row.archivesBasicDataId, null, searchForm)
                }
            },
            // 获取元数据类型设置输入正则
            getNumType(item) {
                let regular = /[^\d]/g
                if (item.archivesBasicTypeCode == "double") {
                    regular = /[^\d.]/g
                }
                this.$set(this.inputForm, item.code, this.inputForm[item.code].replace(regular, ''))
            },
            beforeUpload(file) { // 上传文件之前钩子
                const type = file.name.split('.')[1]
                if (type !== 'xls' && type !== 'xlsx') {
                    this.$message({type: 'error', message: '只支持xls、xlsx文件格式！'})
                    return false
                }
            },
            // 导入
            getImportData(param) {
                this.$refs.importData.init(param, this.searchForm.archivesBasicDataId, '1')
            },
            // 批量下载
            downloadData() {
                if (!this.dataListSelections.length) {
                    this.$message.warning('请至少选择一条数据')
                    return
                }
                if (this.dataListSelections.length > 10) {
                    this.$message.warning('最多选择10条数据')
                    return
                }
                const h = this.$createElement;
                this.$msgbox({
                    title: '提示',
                    message: h('p', null, [
                        h('p', {style: 'color: #000000; margin-bottom: 5px'}, '确认下载所选数据？'),
                        h('p', {style: 'color: #999999; font-size: 12px'}, '仅下载有附件的档案数据')
                    ]),
                    showCancelButton: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(() => {
                    let ids = this.dataListSelections.map(item => {
                        return item.id
                    }).join(',')
                    this.$message.success('数据下载中请稍后')
                    this.$axios(this.api.record.checkDownloadArchivesFileZips, {
                        archivesDataId: ids,
                    }, 'get').then(res => {
                        if(res.status) {
                            this.$axios(this.api.record.downloadArchivesFileZips, {
                                archivesDataId: ids,
                            }, 'get', 'blob').then(res => {
                                let blob = new Blob([res.data], {type: 'application/zip'})
                                let link = document.createElement('a')
                                link.download = decodeURI('下载档案')
                                link.href = window.URL.createObjectURL(blob)
                                link.click()
                                setTimeout(() => {
                                    this.$message.success('下载成功')
                                    this.srhListData(0)
                                }, 1000)
                            })
                        } else {
                            this.$message.error(res.msg)
                        }
                    })

                });
            },
            // 导入模板下载
            importTemplate() {
                this.exportExcel(this.api.record.exportModel, {
                    archivesBasicDataId: this.searchForm.archivesBasicDataId
                }, this.archivesBasicDataName)
            },
            // 导出
            exportData() {
                if (!this.dataListSelections.length) {
                    this.$message.warning('请至少选择一条数据')
                    return
                }
                let idList = this.dataListSelections.map(item => {
                    return item.id
                })
                this.$refs.exportData.init(idList, this.config, this.searchForm.archivesBasicDataId, this.archivesBasicDataName)
            },
            // 文件挂接
            fileLinkageData() {
                this.$refs.fileLinkage.init()
            },
            // 复制
            copy(item) {
                this.copyData = item
                this.checkReference(0, 1)
            },
            // 检验档号是否重复  checkType 1手动输入档号，0默认赋值；type 1保存
            checkReference(checkType, type) {
                this.$axios(this.api.record.checkReference, {
                    reference: this.copyData.reference,
                }, 'get').then(res => {
                    if (res.status) {
                        if(type) {
                            this.copyArchivesData(checkType)
                        }
                    } else {
                        if(!this.visible) {
                            this.visible = true
                        }
                        this.$nextTick(() => {
                            this.$message.error(res.msg)
                        })
                    }
                })
            },
            // 保存档号
            saveCopyData() {
                this.$refs['copyData'].validate((valid) => {
                    if (valid) {
                        this.checkReference(1, 1)
                    }
                })
            },
            // 边输入边校验
            referenceIpt() {
                this.checkReference(1)
            },
            // 复制档案
            copyArchivesData(checkType) {
                this.$axios(this.api.record.copyArchivesData, {
                    archivesDataId: this.copyData.id,
                    reference: checkType ? this.copyData.reference : '',
                }, 'get').then(res => {
                    if (res.status) {
                        this.$message.success('复制成功')
                        this.visible = false
                        this.srhListData(0)
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            },
            // 下载
            download(item) {
                this.$confirm(`确认下载所选数据？`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$axios(this.api.record.getById, {
                        archivesBasicDataId: item.archivesBasicDataId,
                        id: item.id
                    }, 'get').then((res) => {
                        if (res.status && res.data.archivesFileList.length) {
                            this.$axios(this.api.record.downloadArchivesFileZip, {
                                archivesDataId: item.id,
                            }, 'get', 'blob').then(res => {
                                let blob = new Blob([res.data], {type: 'application/zip'})
                                let link = document.createElement('a')
                                link.download = decodeURI('附件')
                                link.href = window.URL.createObjectURL(blob)
                                link.click()
                                setTimeout(() => {
                                    this.srhListData(0)
                                }, 1000)
                            })
                        } else {
                            this.$message.info('当前数据暂无附件数据')
                        }
                    })
                })
            },
            // 删除
            del(id) {
                let ids = [id];
                if (!id && !this.dataListSelections.length) {
                    this.$message.warning('请至少选择一条数据')
                    return
                }
                if (!id) {
                    ids = this.dataListSelections.map(item => {
                        return item.id
                    })
                }
                this.$confirm(`确定删除所选项吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.loading = true
                    this.$axios(this.api.record.removeById, {
                        archivesBasicDataId: this.searchForm.archivesBasicDataId,
                        ids: ids
                    }, 'post').then(data => {
                        if (data.status) {
                            this.$message.success('删除成功')
                            this.srhListData(0)
                        } else {
                            this.$message.error('删除失败')
                        }
                        this.loading = false
                    })
                })
            },
            // 获取用户列表
            getUserList() {
                this.$axios(this.api.auth.sysuserQueryAllList, {
                    current: 1,
                    size: 9999,
                    orderBy: '',
                    name: '',
                    loginName: '',
                }, 'get').then(data => {
                    if (data && data.status) {
                        this.userList = data.data.records
                    }
                })
            },
            //重置
            reset() {
                this.$refs.multipleTable.clearSelection()
                this.$refs.inputForm.resetFields();
                this.getList(1)
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            // 每页数
            sizeChangeHandle(val) {
                this.searchForm.size = val
                this.searchForm.current = 1
                this.srhListData()
            },
            // 当前页
            currentChangeHandle(val) {
                this.searchForm.current = val
                this.srhListData()
            },
        }
    }
</script>

<style scoped>
    .el-form-item--small.el-form-item {
        display: inline-block;
        width: 48%;
    }
    .showListBtn {
        right: auto;
        left: -26px;
    }
</style>
