<template>
    <el-drawer title="案卷档案" class="collectForm" size="50%" :visible.sync="visible" direction="rtl">
        <div class="formBox" v-loading="loading" v-if="haveData">
            <div class="flex_l_t m_b1">
                <div class="label">档案分类：</div>
                <div>{{inputForm.archivesBasicDataName}}</div>
            </div>
            <div class="flex_l_t m_b1">
                <div class="label">档案类型：</div>
                <div>{{inputForm.archivesBasicDataType}}</div>
            </div>
            <div class="flex_l_t m_b1">
                <div class="label">题名：</div>
                <div>{{inputForm.archivesDataName}}</div>
            </div>
            <div class="flex_l_t m_b1">
                <div class="label">状态：</div>
                <div>{{inputForm.warehousingState}}</div>
            </div>
            <div class="flex_l_t m_b1">
                <div class="label">全宗号：</div>
                <div>{{inputForm.quanzongNumber}}</div>
            </div>
            <div class="flex_l_t m_b1">
                <div class="label">全宗名称：</div>
                <div>{{inputForm.quanzongName}}</div>
            </div>
            <div class="flex_l_t m_b1">
                <div class="label">档号：</div>
                <div>{{inputForm.reference}}</div>
            </div>
            <div class="flex_l_t m_b1" v-for="(item, index) in config" :key="index">
                <div class="label">{{item.forName ? item.forName : item.basicName}}：</div>
                <div>{{metadataForm[item.code]}}</div>
            </div>
            <div class="p_all1">
                <el-table :data="fileList" size="small" class="table" v-if="fileList.length">
                    <el-table-column prop="fileName" label="题名" width="180" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <span>{{scope.row.fileName}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="fileFormat" label="文件类型">
                        <template slot-scope="scope">
                            <img :src="getFileIcon(scope.row.fileFormat)" class="fileIcon">
                        </template>
                    </el-table-column>
                    <el-table-column prop="queryCount" label="查看次数"
                                     v-if="hasPermission(info.archivesBasicDataId + '-queryPermissions-1') || hasPermission(id + '-queryPermissions-1')"/>
                    <el-table-column prop="downloadCount" label="下载次数"
                                     v-if="hasPermission(info.archivesBasicDataId + '-downloadPermissions-1') || hasPermission(id + '-downloadPermissions-1')"/>
                    <el-table-column prop="printCount" label="打印次数"
                                     v-if="hasPermission(info.archivesBasicDataId + '-printPermissions-1') || hasPermission(id + '-printPermissions-1')"/>
                    <el-table-column fixed="right" width="180" label="操作">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="preview(scope.row)"
                                       v-if="hasPermission(scope.row.archivesBasicDataId + '-queryPermissions-1') ||  hasPermission(scope.row.archivesDataId + '-queryPermissions-1')">
                                预览
                            </el-button>
                            <el-button type="text" size="small"
                                       v-if="hasPermission(scope.row.archivesBasicDataId + '-downloadPermissions-1') || hasPermission(scope.row.archivesDataId + '-downloadPermissions-1')"
                                       @click="download(scope.row)">下载
                            </el-button>
                            <el-button type="text" size="small"
                                       v-if="scope.row.fileFormat == 'pdf' && hasPermission(scope.row.archivesBasicDataId + '-printPermissions-1') || scope.row.fileFormat == 'pdf' && hasPermission(scope.row.archivesDataId + '-printPermissions-1') "
                                       @click="print(scope.row)">打印
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="noData" v-if="!loading && !haveData">
            <img src="@/assets/img/noData.png">
            <div>暂无相关数据</div>
        </div>
        <div class="text_center p_t2" v-if="haveData">
            <el-button size="small" @click="visible = false">返回</el-button>
            <el-button size="small" type="primary" @click="view()" v-noMoreClick>详情</el-button>
        </div>
        <Preview ref="preview"></Preview>
        <ListForm ref="listForm" @refreshDataList="getInfo()"></ListForm>
    </el-drawer>
</template>

<script>
    import Preview from "../collect/preview";
    import ListForm from './listForm'

    export default {
        components: {
            Preview, ListForm
        },
        data() {
            return {
                visible: false,
                loading: false,
                info: {},
                config: [],
                inputForm: {
                    archivesBasicDataName: '',
                    archivesBasicDataType: '',
                    archivesDataName: '',
                    warehousingState: '',
                    borrowingState: '',
                    quanzongNumber: '',
                    quanzongName: '',
                    reference: '',
                },
                fileList: [],
                metadataForm: {},
                dossierInfo: {},
                haveData: false
            }
        },
        methods: {
            init(row) {
                this.loading = false
                this.visible = true
                this.info = row
                this.getInfo()
                // this.getRecord()
            },
            // 获取数据类型
            getRecord(id) {
                this.$axios(this.api.record.basicdataGetById + '/' + id).then(data => {
                    if (data.status) {
                        this.config = data.data.archivesBasicDataGroup
                    }
                })
            },
            // 查询详情数据
            getInfo() {
                this.loading = true
                this.$axios(this.api.record.withinTheVolumeByArchivesDataId, {
                    archivesDataId: this.info.id,
                    basicDataId: this.info.archivesBasicDataId,
                }, 'get').then((res) => {
                    if (res.status) {
                        this.getRecord(res.data.archivesBasicDataId)
                        this.haveData = true
                        this.dossierInfo = res.data
                        this.inputForm = this.recover(this.inputForm, res.data)
                        this.fileList = res.data.archivesFileList
                        this.metadataForm = res.data.archivesData
                        this.config.map(item => {
                            if(item.archivesBasicTypeCode == 'date' && item.dataFormat != 'yyyy-MM-dd HH:mm:ss') {
                                let date = this.metadataForm[item.code]
                                if(date && item.dataFormat == "yyyy-MM-dd") {
                                    this.$set(this.metadataForm, item.code, date.split(" ")[0])
                                }
                                if(date && item.dataFormat == "yyyyMMdd") {
                                    let val = date.split(" ")[0].split("-")
                                    this.$set(this.metadataForm, item.code, val[0] + val[1] + val[2])
                                }
                            }
                        })
                    } else {
                        this.haveData = false
                    }
                    this.loading = false
                })
            },
            // 详情
            view() {
                this.$refs.listForm.init(this.dossierInfo, 'view', this.dossierInfo.archivesBasicDataId, 'dossierDetails')
            },
            // 打印
            print(item) {
                this.loading = true
                this.$axios(this.api.record.utilizePrint, {archivesFileId: item.id}, 'get').then(res => {
                    if (res.status) {
                        this.$print({
                            printable: res.data,
                            type: item.fileFormat,
                            header: null,
                            targetStyles: ['*'],
                            style: "@page {margin:0 10mm}"
                        })
                        this.loading = false
                        setTimeout(() => {
                            this.getInfo()
                            let FirefoxDiv = document.getElementById('printJS')
                            if (navigator.userAgent.indexOf("Firefox") > 0) {
                                FirefoxDiv.style.cssText = 'width:0;height:0;position:absolute;visibility:hidden;border:0';
                            }
                        }, 1000)
                    } else {
                        this.$message.error('打印失败');
                    }
                })

            },
            // 下载
            download(item) {
                this.$confirm(`确认下载所选数据？`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.loading = true
                    this.$axios(this.api.record.downloadArchivesFile, {
                        archivesFileId: item.id,
                    }, 'get', 'blob').then(res => {
                        let blob = new Blob([res.data])
                        let link = document.createElement('a')
                        link.download = decodeURI(item.fileName)
                        link.href = window.URL.createObjectURL(blob)
                        link.click()
                        this.loading = false
                        setTimeout(() => {
                            this.getInfo()
                        }, 1000)
                    })
                })
            },
            // 预览
            preview(item) {
                this.loading = true
                this.$axios(this.api.record.utilizeQuery, {archivesFileId: item.id}, 'get').then(res => {
                    if (res.status) {
                        let data = JSON.parse(JSON.stringify(item))
                        data.accessPath = res.data
                        this.$refs.preview.init(data)
                        setTimeout(() => {
                            this.getInfo()
                        }, 1000)
                    } else {
                        this.$message.error('预览失败');
                    }
                    this.loading = false
                })
            },
        }
    }
</script>

<style scoped lang="scss">
    .label {
        width: 150px;
        text-align: right;
    }
    .noData {
        padding-top: 100px;
        text-align: center;
        img {
            width: 200px;
        }
    }
</style>
